import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/modal.vue";

@Options({
    name: "app-draggable-listview",
    components: {
        "app-modal": Modal,
    },
    props: {
        modelValue: Array,
        submitFunction: Function,
        title: {
            type: String,
            default: "排序",
        },
        group: {
            type: String,
            default: "group1",
        },
    },
    emits: ["update:modelValue"],
})
export default class DraggableListview extends Vue {
    private draggableListviewModalElement: any;
    private modelValue: any = [];
    private drag = false;

    get draggableList() {
        return this.modelValue;
    }

    set draggableList(value: any) {
        this.$emit("update:modelValue", value);
    }

    public async mounted(): Promise<void> {
        this.draggableListviewModalElement = this.$refs.draggableListviewModal;
    }

    public openModal(fixed = true) {
        this.draggableListviewModalElement.openModal(fixed);
    }

    public closeModal() {
        this.draggableListviewModalElement.closeModal();
    }

    public toggleModal(fixed = true) {
        this.draggableListviewModalElement.toggleModal(fixed);
    }
}
